import Color from 'styles/colors';
import { css } from '@emotion/css';
import { Weight } from 'styles/font';

const flexRow = css`
  display: flex;
  flex-direction: row;
`;

const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const container = css`
  ${flexColumn}
  cursor: pointer;
  background-color: ${Color.White};
  justify-items: center;
  width: 8.875rem;
`;

export const info = css`
  ${flexColumn}
  gap: 0.25rem;
  margin-top: 0.625rem;
`;

export const thumbnailWrap = css`
  height: 8.875rem;
  min-height: 8.875rem;
  border-radius: 0.5rem;
  position: relative;
  overflow: hidden;
`;

export const thumbnail = css`
  width: 8.875rem;
  height: 8.875rem;
  object-fit: cover;
`;

export const thumbnailDisabled = css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;

  text-align: center;
  color: ${Color.White};
  background-color: ${Color.BlackAlpha['40']};
`;

export const hospital = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Color.Gray['70']};
`;

export const title = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Color.Gray['90']};
`;

export const discountRate = css`
  color: ${Color.Primary};
  font-weight: ${Weight.Bold};
`;

export const price = css`
  font-weight: ${Weight.Bold};
  color: ${Color.Gray['100']};
`;

export const priceRow = css`
  ${flexRow}
  gap: 0.375rem;
  margin-top: 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  & span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.125rem;
    margin-bottom: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
