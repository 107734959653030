import React from 'react';
import * as styles from './styles';

export type DimmerProps = {
  children: React.ReactNode;
  hidden?: boolean;
};

export default function Dimmer({ children, hidden = true }: DimmerProps) {
  return <div className={styles.dimmer(hidden)}>{children}</div>;
}
