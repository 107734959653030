import Color from 'styles/colors';
import { css } from '@emotion/css';
import { Weight } from 'styles/font';

const flexRow = css`
  display: flex;
  flex-direction: row;
`;

const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const container = css`
  ${flexColumn}
  cursor: pointer;
  background-color: ${Color.White};
  width: 100%;
  gap: 0.875rem;
`;

export const content = css`
  ${flexRow}
  align-items: center;
  gap: 0.625rem;
  width: 100%;
`;

export const info = css`
  ${flexColumn}
  max-width: calc(100% - 5rem);
  overflow: hidden;
`;

export const thumbnailWrap = css`
  width: 5rem;
  height: 5rem;
  min-width: 5rem;
  min-height: 5rem;
  border-radius: 0.5rem;
  position: relative;
  overflow: hidden;
`;

export const thumbnail = css`
  object-fit: cover;
`;

export const thumbnailDisabled = css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  text-align: center;
  background-color: ${Color.BlackAlpha['40']};
`;

export const hospital = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Color.Gray['70']};
`;

export const title = css`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Color.Gray['90']};
`;

export const tagRow = css`
  min-height: 1.375rem;
`;

export const tag = css`
  margin: 3px 0;
  width: fit-content;
`;

export const discountRate = css`
  color: ${Color.Primary};
  font-weight: ${Weight.Bold};
`;

export const price = css`
  font-weight: ${Weight.Bold};
  color: ${Color.Gray['100']};
`;

export const priceRow = css`
  ${flexRow}
  gap: 0.375rem;
  margin-bottom: 0;

  & span {
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 0.125rem;
    margin-bottom: 0;
  }
`;
