import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web-light';
import Dimmer from 'components/Overlay/Dimmer';
import loadingLottieData from 'assets/lottie/loading.json';
import * as styles from './styles';

export type LoaderProps = {
  visible: boolean;
};

export default function Loader(props: LoaderProps) {
  const { visible } = props;
  const loadingLottieRef = useRef();
  useEffect(() => {
    lottie.loadAnimation({
      container: loadingLottieRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: loadingLottieData,
    });
    return () => {
      lottie.destroy();
    };
  }, []);

  return (
    <Dimmer hidden={!visible}>
      <div className={styles.lottie} ref={loadingLottieRef} />
    </Dimmer>
  );
}
