import { forwardRef, HTMLAttributes, useMemo } from 'react';

import Text from 'components/Typography/Text';
import Tag from 'components/DataDisplay/Tag';
import hospitalDetailSquareImg from 'assets/images/img_hospital_detail_square.png';

import { calcDiscountRate } from 'utils/calculate';
import { getFormattedNumberKo } from 'utils/format';
import useNavigation, { PageName } from 'hooks/useNavigation';
import { redirectImageUrl } from 'utils/url-helper';
import * as styles from './styles';
import { ClinicEvent } from './types';

export type TagType = { label: string; color: 'red' | 'green' | 'blue' | 'gray' };
export interface ClinicEventCardProps extends HTMLAttributes<HTMLDivElement> {
  clinicEvent: ClinicEvent;
  tag?: TagType;
  enabled?: boolean;
  hideTag?: boolean;
  onBeforeCardClicked?: (event: ClinicEvent) => void;
}

const ClinicEventCard = forwardRef<HTMLDivElement, ClinicEventCardProps>((props, ref) => {
  const { clinicEvent, tag, enabled = true, onBeforeCardClicked, className, hideTag = false, ...restProps } = props;
  const { routerPush } = useNavigation();

  const priceData: {
    discountRate: string;
    price: string | null;
  } = useMemo(() => {
    const { originalPrice, price } = clinicEvent;
    const discountRate = calcDiscountRate(price, originalPrice);
    return {
      discountRate: discountRate === '0' ? null : `${discountRate}%`,
      price: !price ? null : getFormattedNumberKo(price),
    };
  }, [clinicEvent]);

  const onClickCardAction = () => {
    onBeforeCardClicked?.(clinicEvent);
    routerPush(PageName.eventDetails, { eventId: clinicEvent.id });
  };

  return (
    <div className={`${styles.container} ${className ?? ''}`} onClick={onClickCardAction} ref={ref} {...restProps}>
      <div className={styles.content}>
        <div className={styles.thumbnailWrap}>
          <img
            src={redirectImageUrl(clinicEvent?.thumbnailImage, 240) || hospitalDetailSquareImg}
            className={styles.thumbnail}
            alt="thumbnail"
            width={80}
            height={80}
          />
          {!enabled && (
            <div className={styles.thumbnailDisabled}>
              <Text.Body2>이벤트 종료</Text.Body2>
            </div>
          )}
        </div>
        <div className={styles.info}>
          <Text.Body3 className={styles.hospital}>{clinicEvent?.hospital?.name}</Text.Body3>
          <Text.Body1Medium className={styles.title}>{clinicEvent?.title}</Text.Body1Medium>
          {!hideTag && (
            <span className={styles.tagRow}>
              {tag?.label && (
                <Tag size="xSmall" styleType={tag.color} layout={styles.tag}>
                  {tag.label}
                </Tag>
              )}
            </span>
          )}
          <div className={styles.priceRow}>
            {priceData.discountRate && (
              <Text.Body1Bold className={styles.discountRate}>{priceData.discountRate}</Text.Body1Bold>
            )}
            <Text.Body1Bold className={styles.price}>{priceData.price || '병원에 문의하기'}</Text.Body1Bold>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ClinicEventCard;
