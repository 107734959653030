import React, { useState } from 'react';
import LoaderWithDimmer from 'components/Overlay/Loader';

const LoaderContext = React.createContext<any>({});

export type FormTextFieldProps = {
  children: React.ReactNode;
};
const LoaderProvider = (props: FormTextFieldProps) => {
  const { children } = props;
  const [loaderVisible, setLoaderVisible] = useState(false);

  return (
    <LoaderContext.Provider
      value={{
        loaderVisible,
        setLoaderVisible,
      }}
    >
      <LoaderWithDimmer visible={loaderVisible} />
      {children}
    </LoaderContext.Provider>
  );
};

export { LoaderContext, LoaderProvider };
