import { css } from '@emotion/css';
import Color from 'styles/colors';

export const dimmer = (hidden: boolean) => css`
  display: ${hidden ? 'none' : 'flex'};
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  z-index: 999;
  background-color: ${Color.Alpha['70']};
  top: 0;
  align-items: center;
  justify-content: center;
`;
