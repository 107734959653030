import { FC, HTMLAttributes, ReactNode, useRef } from 'react';
import EventSummaryCard from 'components/DataDisplay/Card/ClinicEvent';
import FixSwipeable from 'components/atoms/FixSwipeable';
import { Skeleton } from '@material-ui/lab';
import Spacer from 'components/Layout/Spacer';
import { InView } from 'react-intersection-observer';
import useDraggableScroll from 'hooks/useDragScroll';
import * as styles from './styles';
import { ClinicEvent } from '../types';

interface EventListProps extends HTMLAttributes<HTMLDivElement> {
  clinicEventItems?: ClinicEvent[];
  showAddToCartButton?: boolean;
  customTrailingView?: ReactNode;
  onBeforeCardClicked?: (event: ClinicEvent, index: number) => void;
  onClickAddToCartButton?: (event: ClinicEvent) => void;
  onListImpression?(event: ClinicEvent, index: number): void;
}

const EventList: FC<EventListProps> = (props) => {
  const {
    clinicEventItems,
    showAddToCartButton = false,
    onBeforeCardClicked,
    onClickAddToCartButton,
    className,
    customTrailingView,
    onListImpression,
    ...restProps
  } = props;

  const container = useRef<HTMLDivElement>(null);
  const { onMouseDown } = useDraggableScroll(container);
  // const { addToEventCart, createEventCartLoading } = useEventCartContext();
  // const handleAddToCartButtonClicked = (event: ClinicEvent) => {
  //   addToEventCart(event, null, true);
  //   onClickAddToCartButton?.(event);
  // };

  return (
    <FixSwipeable>
      <div
        className={`${styles.container} ${className ?? ''}`}
        ref={container}
        onMouseDown={onMouseDown}
        {...restProps}
      >
        {clinicEventItems &&
          clinicEventItems.map((clinicEvent, index) => (
            <div key={clinicEvent.id} className={styles.content}>
              <InView
                threshold={0.7}
                delay={100}
                onChange={(inView: boolean) => {
                  if (inView) {
                    onListImpression?.(clinicEvent, index);
                  }
                }}
              >
                <EventSummaryCard.Vertical
                  clinicEvent={clinicEvent}
                  onBeforeCardClicked={(clinicEvent) => {
                    onBeforeCardClicked?.(clinicEvent, index);
                  }}
                />
              </InView>
            </div>
          ))}
        {customTrailingView}
        {!clinicEventItems && <EventScrollSkeleton />}
      </div>
    </FixSwipeable>
  );
};

const EventScrollSkeleton = () => (
  <div className={styles.skeletonFrame}>
    {Array(3)
      .fill(null)
      .map((_, index) => (
        <div className={styles.skeletonCard} key={index}>
          <Skeleton variant="rect" width={142} height={142} className={styles.skeletonImage} />
          <Skeleton variant="rect" width={142} height={20} />
          <Spacer height={8} />
          <Skeleton variant="rect" width={100} height={16} />
          <Spacer height={8} />
          <Skeleton variant="rect" width={100} height={16} />
        </div>
      ))}
  </div>
);

export default EventList;
