import { forwardRef, HTMLAttributes, useContext, useMemo } from 'react';

import Text from 'components/Typography/Text';
import hospitalDetailSquareImg from 'assets/images/img_hospital_detail_square.png';

import { calcDiscountRate } from 'utils/calculate';
import { getFormattedDistance, getFormattedNumberKo } from 'utils/format';
import useNavigation, { PageName } from 'hooks/useNavigation';
import { LocationContext } from 'contexts/LocationContext';
import { redirectImageUrl } from 'utils/url-helper';
import Image from 'next/image';
import { isLiveClinicEvent } from 'utils/validation';
import Color from 'styles/colors';
import * as styles from './styles';
import { ClinicEvent } from '../types';

export interface ClinicEventCardProps extends HTMLAttributes<HTMLDivElement> {
  clinicEvent: ClinicEvent;
  tag?: { label: string; color: 'red' | 'green' | 'blue' | 'gray' };
  onBeforeCardClicked?: (clinicEvent: ClinicEvent) => void;
}

const ClinicEventCard = forwardRef<HTMLDivElement, ClinicEventCardProps>((props, ref) => {
  const { clinicEvent, onBeforeCardClicked, className, ...restProps } = props;
  const { routerPush } = useNavigation();
  const { coordinate } = useContext(LocationContext);

  const enabled = useMemo(() => isLiveClinicEvent(clinicEvent), [clinicEvent]);

  const hospitalInfo: string = useMemo(() => {
    const hospitalInfo = [];
    const { latitude, longitude } = clinicEvent?.hospital || {};

    if (coordinate && latitude && longitude) {
      const distance = getFormattedDistance({
        currentLocation: coordinate,
        latitude,
        longitude,
      });
      hospitalInfo.push(distance);
    }

    hospitalInfo.push(clinicEvent?.hospital?.name);
    return hospitalInfo.join('·');
  }, [clinicEvent]);

  const priceData: {
    discountRate: string;
    price: string | null;
  } = useMemo(() => {
    const { originalPrice, price } = clinicEvent;
    const discountRate = calcDiscountRate(price, originalPrice);
    return {
      discountRate: discountRate === '0' ? null : `${discountRate}%`,
      price: !price ? null : getFormattedNumberKo(price),
    };
  }, [clinicEvent]);

  const handleCardClicked = () => {
    onBeforeCardClicked?.(clinicEvent);
    routerPush(PageName.eventDetails, { eventId: clinicEvent.id });
  };

  return (
    <div className={`${styles.container} ${className ?? ''}`} onClick={handleCardClicked} ref={ref} {...restProps}>
      <div className={styles.thumbnailWrap}>
        <Image
          src={redirectImageUrl(clinicEvent.thumbnailImage, 426) || hospitalDetailSquareImg}
          className={styles.thumbnail}
          alt="thumbnail"
          layout="fill"
          objectFit="cover"
        />
        {!enabled && (
          <div className={styles.thumbnailDisabled}>
            <Text.Body1Bold color={Color.White}>이벤트 종료</Text.Body1Bold>
            <Text.Body3 color={Color.White}>
              본 이벤트는
              <br />
              종료되었습니다.
            </Text.Body3>
          </div>
        )}
      </div>
      <div className={styles.info}>
        <Text.Body2 className={styles.title}>{clinicEvent?.title}</Text.Body2>
        <Text.Body3 className={styles.hospital}>{hospitalInfo}</Text.Body3>
        <div className={styles.priceRow}>
          {priceData.discountRate && (
            <Text.Body1Bold className={styles.discountRate}>{priceData.discountRate}</Text.Body1Bold>
          )}
          <Text.Body1Bold className={styles.price}>{priceData.price || '병원에 문의하기'}</Text.Body1Bold>
        </div>
      </div>
    </div>
  );
});

export default ClinicEventCard;
